import { make } from 'vuex-pathify';
import { naturalCompare, extractOwnDefaultMetrics } from '../../helpers/helpers';

function formatSite(site) {
  return {
    id: site.code,
    name: site.name,
    timezone: site.timezone,
    coords: site.coords,
    location: site.location,
    information: site.information,
    defaultMetrics: site.defaultMetrics,
    ownDefaultMetrics: extractOwnDefaultMetrics(site.defaultMetrics)
  };
}

const state = {
  sites: [],
  selectedSite: null,
  selectedSiteActiveAnalysis: false,
  loadingSites: true
};

const getters = {
  getSites: state => state.sites,
  ...make.getters('selectedSite')
};

const mutations = {
  ...make.mutations(state),
  SET_SELECTED_SITE(state, site) {
    if (site) this.$gtagPlugin.events.Site.select_site({ event_label: site.name });
    state.selectedSite = site;
  }
};

const actions = {
  async setSites({ commit }) {
    commit('SET_LOADING_SITES', true);
    const sites = (await this.$daqApi.get('/sites')).map(formatSite);
    sites.sort((a, b) => naturalCompare(a.name, b.name));
    commit('SET_SITES', sites);
    commit('SET_LOADING_SITES', false);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
