import Vue from 'vue';
import Router from 'vue-router';
import MSIContainer from '@/containers/MSIContainer.vue';

// Views
const SiteSummary = () => import('@/views/SiteSummary.vue');
const SoilingDashboard = () => import('@/views/SoilingDashboard.vue');
const Analysis = () => import('@/views/Analysis.vue');
const LiveIv = () => import('@/views/LiveIv.vue');
const ModuleHealth = () => import('@/views/ModuleHealth.vue');
const GatewayConfig = () => import('@/views/GatewayConfig.vue');
const DaqConfig = () => import('@/views/DaqConfig.vue');
const SensorConfig = () => import('@/views/SensorConfig.vue');
const ModuleConfig = () => import('@/views/ModuleConfig.vue');
const SiteSettings = () => import('@/views/SiteSettings.vue');

// Error Pages
const Error404 = () => import('@/views/errors/Page404.vue');

Vue.use(Router);

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/site-summary',
      name: 'Home',
      component: MSIContainer,
      children: [
        {
          path: 'site-summary',
          name: 'Site Summary',
          component: SiteSummary
        },
        {
          path: 'soiling-dashboard',
          name: 'Soiling Dashboard',
          component: SoilingDashboard
        },
        {
          path: 'analysis',
          name: 'Analysis',
          component: Analysis
        },
        {
          path: 'live-iv',
          name: 'LiveIv',
          component: LiveIv
        },
        {
          path: 'module-health',
          name: 'ModuleHealth',
          component: ModuleHealth
        },
        {
          path: 'daq-config',
          name: 'Daq Config',
          component: DaqConfig
        },
        {
          path: 'sensor-config',
          name: 'Sensor Link',
          component: SensorConfig
        },
        {
          path: 'module-config',
          name: 'Module Config',
          component: ModuleConfig
        },
        {
          path: 'gateway-config',
          name: 'Gateway Config',
          component: GatewayConfig
        },
        {
          path: 'site-settings',
          name: 'Site Settings',
          component: SiteSettings
        }
      ]
    },
    {
      path: '*',
      name: 'Error 404',
      component: Error404
    }
  ]
});

export default router;
