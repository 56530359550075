export default $t => ({
  items: [
    {
      name: $t('nav.site_summary'),
      url: '/site-summary',
      icon: 'icon-globe',
      class: 'first-nav-link'
    },
    {
      name: $t('nav.soiling'),
      icon: 'icon-drop',
      children: [
        {
          name: $t('nav.soiling_dashboard'),
          url: '/soiling-dashboard',
          icon: 'icon-chart'
        },
      ]
    },
    {
      name: $t('nav.analysis'),
      url: '/analysis',
      icon: 'icon-graph'
    },
    {
      name: $t('nav.live_iv'),
      url: '/live-iv',
      icon: 'icon-heart'
    },
    {
      name: $t('nav.module_health'),
      url: '/module-health',
      icon: 'icon-heart'
    },
    {
      name: $t('nav.configuration'),
      icon: 'icon-wrench',
      children: [
        {
          name: $t('nav.module_config'),
          url: '/module-config',
          icon: 'icon-note'
        },
        {
          name: $t('nav.sensor_config'),
          url: '/sensor-config',
          icon: 'icon-note'
        },
        {
          name: $t('nav.daq_config'),
          url: '/daq-config',
          icon: 'icon-note'
        },
        {
          name: $t('nav.gateway_config'),
          url: '/gateway-config',
          icon: 'icon-note'
        },
        {
          name: $t('nav.site_settings'),
          url: '/site-settings',
          icon: 'icon-settings'
        }
      ]
    }
  ]
});
