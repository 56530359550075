<template>
  <b-overlay :show="loading" z-index="10000">
    <template #overlay>
      <msi-spinner />
    </template>

    <div class="app">
      <AppHeader fixed>
        <SidebarToggler class="d-lg-none" display="md" mobile />
        <b-link class="navbar-brand" to="#">
          <img class="navbar-brand-full" src="img/brand/msi_logo.svg" width="125" height="21" alt="MSI Logo">
          <img class="navbar-brand-minimized" src="img/brand/msi_sun_flat.svg" width="30" height="30" alt="MSI Logo">
        </b-link>
        <SidebarToggler class="d-md-down-none" display="lg" />
        <b-navbar-nav class="ml-auto">
          <h6 class="d-none d-sm-block mb-0">Welcome {{ userName }}</h6>
          <MSIHeaderDropdownAccnt @settings="showSettings = true" @alerts="showAlerts = true" />
        </b-navbar-nav>
      </AppHeader>
      <div class="app-body">
        <AppSidebar fixed>
          <SidebarHeader/>
          <SidebarForm/>
          <SiteDropdown class="m-2" :loading="loadingSites" />
          <SidebarNav :navItems="nav"></SidebarNav>
          <div class="d-flex align-items-center justify-content-center m-2 feedback" @click="handleShowFeedback(true)">
            <font-awesome-icon icon="bullhorn" class="feedback-icon mr-2" />
            <h6 class="m-0">Feedback</h6>
          </div>
          <SidebarFooter/>
          <SidebarMinimizer/>
        </AppSidebar>
        <main class="main">
          <keep-alive :exclude="excludeFromKeepAlive">
            <router-view></router-view>
          </keep-alive>
        </main>
      </div>
      <TheFooter>
        <div class="ml-auto">
          <span class="mr-1">Powered by</span>
          <a href="http://morgansolar.com" style="font-weight: 500;">Morgan Solar Inc</a>
        </div>
      </TheFooter>

      <b-modal v-model="showSettings" hide-footer title="Settings">
        <settings @settingsClosed="showSettings = false" />
      </b-modal>

      <b-modal v-model="showFeedback" hide-footer title="Feedback">
        <feedback @close="handleShowFeedback(false)" />
      </b-modal>

      <b-modal v-model="showAlerts" hide-footer title="Alerts">
        <alerts />
      </b-modal>
    </div>
  </b-overlay>
</template>

<script>
import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarFooter,
  SidebarForm,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  Footer as TheFooter
} from '@coreui/vue';

import { BLink, BNavbarNav, BModal, BOverlay } from 'bootstrap-vue';
import { get } from 'vuex-pathify';
import nav from '@/_nav';
import MSIHeaderDropdownAccnt from './MSIHeaderDropdownAccnt.vue';
import SiteDropdown from '../components/SiteDropdown.vue';
import Settings from '../components/Settings.vue';
import Feedback from '../components/Feedback.vue';
import Alerts from '../components/Alerts.vue';
import MsiSpinner from '../components/MsiSpinner.vue';

export default {
  name: 'msicustom',
  components: {
    AppHeader,
    AppSidebar,
    TheFooter,
    MSIHeaderDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
    SiteDropdown,
    Settings,
    Feedback,
    Alerts,
    BLink,
    BNavbarNav,
    BModal,
    BOverlay,
    MsiSpinner
  },
  data() {
    return {
      nav: nav(this.$t.bind(this)).items,
      showSettings: false,
      showFeedback: false,
      showAlerts: false,
      userName: '',
      loading: false,
      excludeFromKeepAlive: ['SiteSettings']
    };
  },
  computed: {
    loadingSites: get('sites/loadingSites'),
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(route => route.name || route.meta.label);
    }
  },
  methods: {
    handleShowFeedback(show) {
      this.showFeedback = show;
    },
    setUserName() {
      this.userName = this.$kauth.idTokenParsed.given_name;
    }
  },
  async mounted() {
    this.loading = true;
    await this.$kauth.getValidToken();
    this.setUserName();
    this.loading = false;
  }
};
</script>

<style>
body {
  overflow: hidden;
}

.sidebar-minimized .sidebar .feedback {
  display: none !important;
}
</style>

<style lang="scss" scoped>
.feedback:hover {
  cursor: pointer;
}

.feedback:hover {
  color: $msi-orange-dark;
}
</style>
